import React from 'react'
import Layout from '../components/layout'

export default class MuchAdoQuestionnaire extends React.Component {

    constructor(props){
        super(props)

        this.state= {
            phone: "",
            tz: 0,
            name: "",
            city: "",
            food: "",
            pet: "",
            crush: "",
            isSubmitting: false
        }
    }

    componentDidMount(){
        let pn = this.getQueryVariable("pn")
        let number
        if (pn) {
            number = [pn.slice(2,5),"-",pn.slice(5,8),"-",pn.slice(8,12)].join("")
        }
        let tz = this.getQueryVariable("z")
        console.log("time zone: ", typeof tz)
        console.log("parsed time zone:", typeof parseInt(tz))
        this.setState({
            phone: pn,
            readablePhone: number,
            tz: parseInt(tz)
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
      }
    submitForm = (e) => {
        e.preventDefault()

        this.setState({
            isSubmitting: true
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                phone: this.state.phone,
                tz: this.state.tz,
                name: this.state.name,
                city: this.state.city,
                food: this.state.food,
                pet: this.state.pet,
                crush: this.state.crush
            })
        };
        fetch('https://much-ado-over-texting-backend.com/create-user', requestOptions)
        // fetch('http://localhost/create-user', requestOptions)
            // .then(response => response.json())
            .then(res => {
                this.setState({
                    isSubmitting: false
                })
                window.alert("Good job, you submitted succesfully! Please look for a text message from us.")
                setTimeout(() => {  
                    this.setState({
                        phone: "",
                        tz: 0,
                        name: "",
                        city: "",
                        food: "",
                        pet: "",
                        crush: "",
                        isSubmitting: false
                    })
                 }, 3000);
            });
    }
    getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
    }

    render(){
        return (
            <Layout>
                <div className="much-ado-body-wrapper">
                    <div className="much-ado-form-wrapper">
                        <p style={{textAlign:"left", fontSize:18, lineHeight:1.35, fontWeight: "bold"}}>If your phone number is {this.state.readablePhone} we'd love to learn a little more about you!<br></br><br></br>   
                        If that is not your number, something is amiss. You can email us at <a href="mailto:messina@foundstages.org?subject=Help!%20Something%20is%20amiss!">messina@foundstages.org</a><br></br><br></br>   
                        These questions are just to add a little personal touch to the experience. We're not doing anything with this info, it's just for fun.<br></br><br></br>   
                        But if you just can't stand it, feel free to give fake answers. It may even be funnier that way...</p>
                        <form class="contact-form-body">
                            <div className="inputs-wrapper">
                                <p className="mado-question" style={{textAlign:"left"}}>What name do your friends call you by? Just a first name will do</p>
                                <input 
                                onChange={this.handleChange}
                                value={this.state.name}
                                id="name"
                                className="contact-input full-width-input contact-less-padding" 
                                name="name" 
                                placeholder="" />
                                <p className="mado-question" style={{textAlign:"left"}}>What city do you live in?</p>
                                <input 
                                onChange={this.handleChange}
                                value={this.state.city}
                                className="contact-input full-width-input contact-less-padding" 
                                name="city" 
                                placeholder="" />
                                <p className="mado-question" style={{textAlign:"left"}}>What's your favorite food?</p>
                                <input 
                                onChange={this.handleChange}
                                value={this.state.food}
                                className="contact-input full-width-input contact-less-padding" 
                                name="food" 
                                placeholder="" />
                                <p className="mado-question" style={{textAlign:"left"}}>What's your childhood pet's name? If you didn't have one, you can make one up.</p>
                                <input 
                                onChange={this.handleChange}
                                value={this.state.pet}
                                className="contact-input full-width-input contact-less-padding" 
                                name="pet" 
                                placeholder="" />
                                <p className="mado-question" style={{textAlign:"left"}}>What's the first name of your significant other ... or your celebrity crush?</p>
                                <input 
                                onChange={this.handleChange}
                                value={this.state.crush}
                                className="contact-input full-width-input contact-less-padding" 
                                name="crush" 
                                placeholder="" />
                            </div>
                            {this.state.isSubmitting ? (
                                <p>Submitting....</p>
                            ) : (
                                <button type="submit" onClick={this.submitForm} className="button--fullWidth blackButton--border">Submit</button>
                            )
                        }
                        </form>
                    </div>
                </div>
            </Layout>
        )
    }
}
